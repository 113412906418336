
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { useRoute, useRouter } from "vue-router"
import ClassroomComponent from "@/components/education/definitions/class-room/class-room.vue"
import { Classroom } from "@/core/models/Classroom"
import { SelectModel } from "@/core/models/SelectModel"

import swalConfirm from "@/core/helpers/swal-confirm"

export default defineComponent({
  name: "class-room-list",
  components: { ClassroomComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()

    const isTableDataLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isBusinessSelectLoading = ref(false)

    const newClassroom = ref(false)

    const branchList = ref<SelectModel[]>([])
    const businessList = ref<SelectModel[]>([])

    const classroomList = ref<Classroom[]>([])
    const activeBranchId = ref<string>()
    const activeBusinessId = ref<string>()

    const activeClassroomId = ref<string | undefined>()

    watch(
      () => route.params.classroom_id,
      newValue => {
        activeClassroomId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newClassroom.value = true == newValue
      }
    )

    onMounted(() => {
      activeClassroomId.value = route.params.classroom_id as string
      newClassroom.value = props.add
      getBusinessList()
      getBranchList(activeBusinessId.value)
    })

    async function getBusinessList() {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      isBusinessSelectLoading.value = false
      if (businessList.value.length === 1) {
        activeBusinessId.value = businessList.value[0].id
        OnChangeBusiness(activeBusinessId.value)
      }
    }

    async function getBranchList(businessId) {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      isBranchSelectLoading.value = false
      if (branchList.value.length === 1) {
        activeBranchId.value = branchList.value[0].id
        await getClassroomList(branchList.value[0].id)
      }
    }

    async function getClassroomList(branchId) {
      if (!branchId) return
      isTableDataLoading.value = true
      const { data } = await ApiService.get("class-room/branch/" + branchId)
      classroomList.value = data
      isTableDataLoading.value = false
    }

    async function removeClassroom(classroomId) {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("class-room/remove/" + classroomId)
        await getClassroomList(activeBranchId.value)
      }
    }

    const OnChangeBusiness = async businessId => {
      activeBranchId.value = ""
      classroomList.value = [] as Classroom[]
      await getBranchList(businessId)
    }

    function handleClassroomDrawerClose() {
      router.push({ name: "class-room-list" })
    }

    return {
      isTableDataLoading,
      classroomList,
      branchList,
      activeBranchId,
      activeBusinessId,
      Edit,
      removeClassroom,
      getClassroomList,
      activeClassroomId,
      newClassroom,
      handleClassroomDrawerClose,
      isBranchSelectLoading,
      isBusinessSelectLoading,
      businessList,
      getBranchList,
      OnChangeBusiness,
    }
  },
})
