
import { SelectModel } from "@/core/models/SelectModel"
import { Classroom } from "@/core/models/Classroom"
import ApiService from "@/core/services/ApiService"

import { computed, defineComponent, ref, onMounted, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"

export default defineComponent({
  name: "class-room-component",
  components: {},
  props: ["add", "id", "closed"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add, id } = toRefs(props)

    const classRoomData = ref<Classroom>({} as Classroom)

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const branchList = ref<SelectModel[]>([])
    const businessList = ref<SelectModel[]>([])

    const rules = ref({
      branchId: getRule(RuleTypes.SELECT, "Şube"),
      name: getRule(RuleTypes.TEXT50, "Ad"),
      studentCapacity: getRule(RuleTypes.NUMBER, "Öğrenci Kapasitesi"),
    })

    const getClassroom = async id => {
      const { data } = await ApiService.get("class-room/" + id)
      classRoomData.value = data
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getClassroom(newValue)
          getBranchList(classRoomData.value.businessId)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          branchList.value = []
          ruleFormRef.value?.resetFields()
          classRoomData.value = {} as Classroom
          initBusinessList()
        }
      }
    )

    async function addClassroom() {
      const data = { ...classRoomData.value }
      await ApiService.post("class-room/add", data)
      emit("getlist")
      router.push({ name: "class-room-list" })
    }
    async function updateClassroom() {
      const data = { ...classRoomData.value, id: props.id }
      await ApiService.post("class-room/edit", data)
      emit("getlist")
      router.push({ name: "class-room-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addClassroom()
          } else {
            updateClassroom()
          }
        }
      })
    }

    onMounted(async () => {
      await getBusinessList()
    })

    async function getBusinessList() {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      initBusinessList()
    }

    const initBusinessList = () => {
      if (businessList.value.length == 1) {
        const onlyBusinessId = businessList.value[0].id
        classRoomData.value.businessId = onlyBusinessId
        getBranchList(onlyBusinessId)
      }
    }

    async function getBranchList(businessId) {
      classRoomData.value.branchId = "" as string
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (branchList.value.length == 1) {
        classRoomData.value.branchId = branchList.value[0].id
      }
    }

    return {
      showDrawer,
      newClassroom: add,
      activeClassroomId: id,
      handleClose: props.closed,
      classRoomData,
      addOrUpdate,
      ruleFormRef,
      branchList,
      businessList,
      getBranchList,
      rules,
    }
  },
})
